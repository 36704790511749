/* Autogenerated file. Do not edit manually. */

/* tslint:disable */

/* eslint-disable */
import type { PromiseOrValue } from "../../../common";
import type {
  PrivateVaultTypeHashs,
  PrivateVaultTypeHashsInterface,
} from "../../../libraries/Constants.sol/PrivateVaultTypeHashs";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";

const _abi = [
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_NAME",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61016e61003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361061004b5760003560e01c80632990395a146100505780637f3ae1ef146100a2578063e0b6f6e5146100d7575b600080fd5b61008c6040518060400160405280601981526020017f707269766174655661756c7440736565646c6973742e6f72670000000000000081525081565b6040516100999190610113565b60405180910390f35b6100c97fdad980a10e49615eb7fc5d7774307c8f04d959ac46349850121d52b1e409fc1e81565b604051908152602001610099565b61008c6040518060400160405280600581526020017f312e302e3000000000000000000000000000000000000000000000000000000081525081565b600060208083528351808285015260005b8181101561014057858101830151858201604001528201610124565b506000604082860101526040601f19601f830116850101925050509291505056fea164736f6c6343000811000a";

type PrivateVaultTypeHashsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: PrivateVaultTypeHashsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class PrivateVaultTypeHashs__factory extends ContractFactory {
  constructor(...args: PrivateVaultTypeHashsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<PrivateVaultTypeHashs> {
    return super.deploy(overrides || {}) as Promise<PrivateVaultTypeHashs>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): PrivateVaultTypeHashs {
    return super.attach(address) as PrivateVaultTypeHashs;
  }
  override connect(signer: Signer): PrivateVaultTypeHashs__factory {
    return super.connect(signer) as PrivateVaultTypeHashs__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): PrivateVaultTypeHashsInterface {
    return new utils.Interface(_abi) as PrivateVaultTypeHashsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PrivateVaultTypeHashs {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as PrivateVaultTypeHashs;
  }
}
